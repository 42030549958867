<template>
  <loading v-if="loading" :type="2" />
  <div id="product-info" v-else>
    <div class="product-info-gradient">
      <div v-if="product_detail.order_status == -1 || product_detail.work_status == -1">
        <p class="worker_state_message">订单已关闭</p>
      </div>
      <div v-else-if="product_detail.order_status ==  -2">
        <p class="worker_state_message">遗留订单</p>
      </div>
      <!-- <div v-else-if="product_detail.order_status == 2">
        <p class="worker_state_message">已指派您，等待商家付款</p>
      </div> -->
      <div v-else-if="product_detail.order_status < 3">
        <div class="span">
          <p class="gradient-title">距离结束{{product_detail.publish_type == '2' ? '接单' : '报价'}}</p> 
        </div>
        <van-count-down :time="new Date(product_detail.closed_at).getTime() - new Date().getTime()" format="DD天HH时mm分ss秒" />
      </div>
      <div v-else-if="product_detail.order_status == 3">
        <p class="worker_state_message" v-if="product_detail.worker_id && product_detail.worker_id == worker_id">订单已指派您</p>
        <p class="worker_state_message">订单已指派其他师傅</p>
      </div>
      <div v-else>
        <p class="worker_state_message">{{ work_status.find(item => item.status == product_detail.work_status).message }}</p>
      </div>
    </div>
    <div class="product-info-list">
      <!-- 修改预约时间 -->
      <div class="info-item" v-if="product_detail.work_status == 3 || product_detail.work_status == 4 || product_detail.work_status == 6">
        <SelectTime @getVisitTime="getVisitTime" :selectTimeDate="selectTimeDate" />
      </div>
      <div class="info-item">
        <div class="flex-between info-title">
          <span class="left-title">订单详情</span>
          <!-- <span class="right-tip">总数3</span> -->
        </div>
        <ul>
          <li class="flex-between info-line" v-if="product_detail.category_name">
            <span>订单类型</span>
            <span>{{product_detail.category_name}} - {{product_detail.service_mode_name}}</span>
            <span>
            </span>
          </li>
          <li class="flex-between info-line" v-if="product_detail.client_name && product_detail.client_mobile">
            <span>订单编号</span>
            <span>{{product_detail.order_id}}</span>
            <span>
            </span>
          </li>
          <li class="flex-between info-line">
            <span>下单方</span>
            <span>{{product_detail.contact_name}}</span>
            <span>
              <a :href="`tel:${product_detail.contact_mobile}`">
                <svg class="icon icon-reset-order-info" aria-hidden="true">
                  <use xlink:href="#icon-xiaochengxu_tubiao"></use>
                </svg>
              </a>
            </span>
          </li>
          
          <li class="flex-between info-line" v-if="product_detail.publish_type == '2'">
            <span>一口价</span>
            <span :style="{color: 'orange', fontWeight: '600'}">¥{{product_detail.fixed_price}} 元</span>
            <span />
          </li>

          <li class="flex-between info-line" v-if="product_detail.worker_id">
            <span>订单金额</span>
            <span :style="{color: 'orange', fontWeight: '600'}">{{product_detail.quote_price}} 元</span>
            <span @click="navigation">
            </span>
          </li>
          <li class="flex-between info-line" v-if="product_detail.full_address">
            <span>优先考虑</span>
            <span>诚信保证金师傅</span>
            <span @click="navigation">
            </span>
          </li>
        </ul>
      </div>
      <div class="info-item">
        <div class="flex-between info-title">
          <span class="left-title">安装地址</span>
          <!-- <span class="right-tip">总数3</span> -->
        </div>
        <ul>
          <li class="flex-between info-line" v-if="product_detail.client_name && product_detail.client_mobile">
            <span>客户姓名</span>
            <span>{{product_detail.client_name}} - {{product_detail.client_mobile}}</span>
            <span>
            </span>
          </li>
          <li class="flex-between info-line" v-if="product_detail.full_address">
            <span>安装地址</span>
            <span>{{product_detail.full_address}}</span>
            <span @click="navigation">
              <svg class="icon icon-reset-order-info" aria-hidden="true">
                <use xlink:href="#icon-ditu"></use>
              </svg>
            </span>
          </li>
        </ul>
      </div>
      <div class="info-item">
        <div class="flex-between info-title">
          <span class="left-title">
            商品信息
          </span>
          <span class="right-tip" v-if="product_detail.commoditys">总数 {{product_detail.commoditys.length}}</span>
        </div>
        <ul>
          <li v-for="(item,index) in product_detail.commoditys" :key="index">
            <div class="product-list">
              <div class="product-item-img" :style="`background: url(${cdnHttp}${item.product_images.split(',')[0]}) no-repeat center / cover`" @click="previewImg(item)">
              </div>
              <div class="product-item-content">
                <span class="product-item-des" v-if="product_detail.category_name || item.l2_category.type_name || item.l3_category.type_name">{{product_detail.category_name}}-{{item.l2_category.type_name}}-{{item.l3_category.type_name}}</span>
                <p v-for="(k,index) in item.additional_value_list" :key="index">
                  <span class="product-item-add-1" v-if="item.additional_value_list">
                    {{k}}
                  </span>
                </p>
                <template v-if="item.additionals.fields">
                  <p class="product-item-add-1" v-if="item.additionals.fields.chairNumber && item.additionals.fields.accessory == '含椅子'">椅子数量:*{{item.additionals.fields.chairNumber}}</p>
                </template>
                <p v-if="item.product_model">商品型号:{{item.product_model}}</p>

                <!-- 规格 -->
                <!-- <span class="product-item-additionals">规格</span> -->
              </div>
              <span class="product-item-quantity" v-if="item.quantity">{{item.quantity}}张</span>
            </div>
            <div>
              <p style="fontSize:1.2rem" v-if="item.special_requirement">特殊要求:{{item.special_requirement}}</p>
            </div>
          </li>
        </ul>
      </div>
      <div class="info-item" v-if="product_detail.logistics">
        <div class="flex-between info-title">
          <span class="left-title">物流详情</span>
          <!-- <span class="right-tip">总数3</span> -->
        </div>
        <ul>
          <li class="flex-between info-line" v-if="product_detail.logistics.status != undefined">
            <span>到货情况</span>
            <span>{{product_detail.logistics.status == 0? '未到货': '已到货'}}</span>
            <span></span>
          </li>
          <li class="flex-between info-line" v-if="product_detail.number">
            <span>物流单号</span>
            <span>{{product_detail.number}}</span>
            <span></span>
          </li>
          <li class="flex-between info-line" v-if="product_detail.logistics.company">
            <span>物流公司</span>
            <span>{{product_detail.logistics.company}}</span>
            <span></span>
          </li>
          <li class="flex-between info-line" v-if="product_detail.logistics.pick_up_address">
            <span>提货地址</span>
            <span>{{product_detail.logistics.pick_up_address}}</span>
            <span></span>
          </li>
          <li class="flex-between info-line" v-if="product_detail.elevator != undefined && product_detail.service_mode.express_mode != 0 ">
            <span>电梯</span>
            <span>{{product_detail.logistics.status == 0? '有电梯': '步梯'}}</span>
            <span></span>
          </li>
          <li class="flex-between info-line" v-if="product_detail.logistics.pick_up_mobile">
            <span>提货电话</span>
            <span>{{product_detail.logistics.pick_up_mobile}}</span>
            <span></span>
          </li>
          <li class="flex-between info-line" v-if="product_detail.logistics.quanlity">
            <span>货物数量</span>
            <span>{{product_detail.logistics.quanlity}}</span>
            <span></span>
          </li>
          <li class="flex-between info-line" v-if="product_detail.logistics.cubic_meter">
            <span>货物立方米</span>
            <span>{{product_detail.logistics.cubic_meter}}</span>
            <span></span>
          </li>
          <li class="flex-between info-line" v-if="product_detail.logistics.whether_pay">
            <span>是否需要代付</span>
            <span>{{product_detail.logistics.whether_pay}}</span>
            <span></span>
          </li>
          <li class="flex-between info-line" v-if="product_detail.logistics.freight">
            <span>货物价格</span>
            <span>{{product_detail.logistics.freight}}</span>
            <span></span>
          </li>
        </ul>
      </div>
      <div class="info-item">
        <div class="flex-between info-title">
          <span class="left-title">其他要求</span>
        </div>
        <ul>
          <p class="other-tip">完工时间无强制要求，可与下单方协商。</p>
          <div class="other-des-list">
            <p> 1. 请尽早与客户预约上门时间，并且在上门前1小时再次电话确认 </p>
            <p> 2. 服务过程中有任何问题及时与商家联系</p>
          </div>
        </ul>
      </div>
      <div class="info-item">
        <ul>
          <li class="flex-between info-line" v-if="product_detail.full_address" @click="goSubPage('promise_data')">
            <span>我的服务承诺</span>
            <span></span>
            <span>
              <van-icon name="arrow" />
            </span>
          </li>
        </ul>
      </div>
    </div>
    <div class="fixed-bottom-button" v-if="product_detail.refund_status == 1">
      <van-button type="primary" block color="linear-gradient(to right, #ff6034, #ee0a24)" text="去退款" @click="goSubPage('refund',product_detail.id)"></van-button>
    </div>
    <div class="fixed-bottom-button" v-else-if="[3,2].indexOf(product_detail.refund_status) !== -1">
    </div>
    <div class="fixed-bottom-button" v-else-if="needButton">
      <van-button type="primary" block color="linear-gradient(to right, #ff6034, #ee0a24)" :text="buttonText" @click="buttonClick"></van-button>
    </div>
  </div>
</template>
<script>
import { ImagePreview } from "vant";
import SelectTime from "@/components/effect/SelectTime.vue";
import Loading from "@/components/public/animation/Loading";
import QueryDicationary from "@/utils/QueryDicationary";
const dicationary = new QueryDicationary();
export default {
  props: {
    request_url: {
      type: String,
      default: "",
    },
    needButton: {
      type: Boolean,
      default: true,
    },
    buttonText: {
      type: String,
      default: "点击",
    },
  },
  components: {
    SelectTime,
    Loading,
  },
  data() {
    return {
      additional_value_list: [],
      loading: true,
      product_detail: {},
      selectTimeDate: {
        title: "修改预约时间",
        event: "submitTimer",
        buttonTitle: "确定时间",
        haveVaule: "",
      },
      newCenter: [],
      time: undefined,
      status: 0,
      worker_id: '',
      order_status: [
        {
          status: 0,
          message: "等待报价",
        },
        {
          status: 1,
          message: "师傅已报价",
        },
        {
          status: 2,
          message: "已指派您",
        },
        {
          status: 3,
          message: "已托管费用",
        },
        {
          status: 4,
          message: "师傅过程",
        },
        {
          status: 5,
          message: "施工完成/等待验收",
        },
        {
          status: 6,
          message: "验收不合格",
        },
        {
          status: 7,
          message: "验收通过/等待确认打款",
        },
        {
          status: 8,
          message: "已打款/待评价",
        },
        {
          status: 9,
          message: "评价完成",
        },
        {
          status: -1,
          message: "其他异常",
        },
        {
          status: -2,
          message: "关闭订单",
        },
      ],
      work_status: [
        {
          status: 0,
          message: "商家已下单",
        },
        {
          status: 1,
          message: "待预约",
        },
        {
          status: 2,
          message: "预约失败",
        },
        {
          status: 3,
          message: "预约成功",
        },
        {
          status: 4,
          message: "待提货",
        },
        {
          status: 5,
          message: "提货失败",
        },
        {
          status: 6,
          message: "提货成功/待签到",
        },
        {
          status: 7,
          message: "签到失败",
        },
        {
          status: 8,
          message: "已签到/待验货",
        },
        {
          status: 9,
          message: "验货异常",
        },
        {
          status: 10,
          message: "验货成功/待完工",
        },
        {
          status: 11,
          message: "完工失败",
        },
        {
          status: 12,
          message: "完工成功/待验收",
        },
        {
          status: 13,
          message: "验收失败",
        },
        {
          status: 14,
          message: "验收成功",
        },
        {
          status: 15,
          message: "待退款",
        },
        {
          status: 16,
          message: "退款成功",
        },
        {
          status: 17,
          message: "交易成功",
        },
        {
          status: -1,
          message: "验货成功/待完工",
        },
        {
          status: -2,
          message: "关闭订单",
        },
      ],
    };
  },
  created() {
    this.getWorkerInfo();
    this.getProduct_detail();
    console.log(this.product_detail);
  },
  methods: {
    getWorkerInfo() {
      this.$http.get(`/api/v2/worker/profile`).then((res) => {
        this.worker_id = res.data.profile.worker_id
      });
    },
    getProduct_detail() {
      this.$http
        .get(
          `/api/v2/worker/order/${this.$route.params.orderId}${this.request_url}`
        )
        .then((res) => {
          this.product_detail = res.data.order;
          if (this.product_detail.reservations != undefined) {
            this.selectTimeDate.haveVaule = this.product_detail.reservations.visit_time;
          }
          console.log(
            res.data.order.commoditys[0].additionals.fields,
            "1232131"
          );

          this.product_detail.commoditys.forEach((item, index) => {
            dicationary.getDicationary(item.additionals.fields).then((res) => {
              item.additional_value_list = res;
              this.$forceUpdate();
            });
          });

          this.loading = false;
          console.log(this.product_detail, "23123123123213");
          /**
           * 调用获取时间
           */
          this.updateTime();
          /**
           * 返回父组件特殊信息
           */
          let specil_info = {
            worker_certification: res.data.order.worker_certification,
          };
          this.$emit("specilInfo", specil_info);

          console.log("订单详情页信息", this.product_detail);
        });
    },
    updateTime() {
      let _time = new Date(
        this.product_detail.created_at.replace(/-/g, "/")
      ).getTime();
      console.log(_time, "sssss");
      let _end_time = _time + 24 * 60 * 60 * 1000;
      let _now_time = new Date().getTime();
      // console.log(_now_time,_end_time,_time)
      this.time = _end_time - _now_time;
    },
    navigation() {
      console.log(1);
      this.show = true;
      this.adress = `${this.product_detail.full_address}`;
      this.$ajax
        .get(
          "https://restapi.amap.com/v3/geocode/geo?key=d70cea9ba6be99be2fd08dc1aba72b96&s=rsv3&&address=" +
            this.adress
        )
        .then((res) => {
          console.log(res);
          this.newCenter = res.data.geocodes[0].location.split(",");
          let map = new AMap.Map("installAdress", {
            resizeEnable: true,
            center: this.newCenter,
          });
          window.location.href = `//uri.amap.com/search?keyword=${this.product_detail.full_address}&center=${res.data.geocodes[0].location}&city=${res.data.geocodes[0].adcode}&view=map&src=mypage&coordinate=gaode&callnative=1`;
          console.log(3);
        });
    },
    buttonClick() {
      this.$emit("buttonClick", this.product_detail.id);
    },
    previewImg(item) {
      let imgList = [];
      item.product_images.split(",").forEach((item) => {
        imgList.push(`${this.cdnHttp}${item}`);
      });
      ImagePreview({
        images: imgList,
        closeable: true,
      });
    },
    /**
     * 根据额外字段中英文对照表写入额外字段
     * @URL: api / v2 / common / fields / dictionary
     */
    conversionAdditional(arr) {},
    getVisitTime() {},
  },
};
</script>
<style lang="less" scoped>
#product-info {
  .flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .product-info-gradient {
    width: 100%;
    height: 19rem;
    background: linear-gradient(to bottom, #ff7963, #ff816c00);
    box-sizing: border-box;
    padding: 2rem 1.4rem 1.2rem 1.4rem;
    .van-count-down,
    .gradient-title {
      letter-spacing: 0.1rem;
      color: #fff;
      padding: 0.8rem 0;
    }
    .worker_state_tip {
      font-size: @theme-font-size-l;
      color: #fff;
      padding: 1rem 0 0 0;
    }
    .worker_state_message {
      font-size: @theme-font-size-large;
      font-weight: 600;
      color: #fff;
      padding: 2rem 0 0 0;
    }
    .gradient-title {
      font-size: @theme-font-size-l;
    }
    .van-count-down {
      font-size: @theme-font-size-xxl;
    }
  }
  .product-info-list {
    width: 95%;
    margin: 0 auto;
    transform: translateY(-9rem);
    .product-list:last-child,
    .product-list:first-child {
      border: none;
    }
    .other-tip {
      color: orange;
      font-size: @theme-font-size-m;
      padding: 0 0 1rem 0;
    }
    .other-des-list {
      min-height: 4rem;
      background: #ff930011;
      border-radius: 0.4rem;
      padding: 1rem;
      line-height: 2rem;
      font-size: @theme-font-size-m;
      p {
        padding: 0.2rem 0;
      }
    }
    .product-list {
      padding: 1rem 0;
      border-bottom: 1px solid @border-color-1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .product-item-img {
        width: 7rem;
        height: 7rem;
        border-radius: 5px;
        background: url("https://cdn.yishifu.cn/image/JaI2SCC5sky9jlelD3q7x64Hi5phFX7xS6Y22LCP.jpg")
          no-repeat center / cover;
      }
      .product-item-content {
        // flex: 3.5;
        display: flex;
        flex-direction: column;
        padding-left: 2rem;
        width: 50%;
        word-wrap: break-word;
        .product-item-des {
          font-size: @theme-font-size-m;
        }
        .product-item-add {
          font-size: @theme-font-size-m;
          color: @themeFontColor;
        }
        .product-item-add-1 {
          font-size: 1rem;
        }
        .product-item-additionals {
          font-size: @theme-font-size-s;
        }
      }
      .product-item-quantity {
        width: 20%;
        font-size: @theme-font-size-m;
        text-align: right;
      }
    }
    .info-item {
      padding: 1rem 1rem;
      margin: 1rem 0;
      overflow: hidden;
      border-radius: 8px;
      background: #fff;
      .info-title {
        // border-bottom: 1px solid @border-color-1;
        line-height: 5.5rem;

        .left-title {
          font-size: @theme-font-size-xl;
          font-weight: 600;
        }
        .right-tip {
          font-size: @theme-font-size-l;
          font-weight: 600;
        }
      }
      .info-line:last-child {
        border: none;
      }

      .info-line {
        padding: 1rem 0;
        border-bottom: 1px solid @border-color-1;
        span {
          font-size: @theme-font-size-m;
        }
        span:first-child {
          flex: 1.8;
          color: @themeFontColor;
        }
        span:nth-child(2) {
          flex: 4;
        }
        span:last-child {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .fixed-bottom-button {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
</style>