<template>
  <div id="SelectTime">
    <van-cell :value="visit_time?visit_time:selectTimeDate.haveVaule" is-link @click="timeSelect">
      <template #title>
        <span class="custom-title">{{selectTimeDate.title}}</span>
      </template>
    </van-cell>
    <!-- 时间选择 -->
    <van-popup v-model="Show" position="bottom" :style="{ height: '100rem' }" :overlay="false">
      <!-- <van-nav-bar :title="selectTimeDate.title" left-text="关闭" @click-left="onClickLeft" :border="false" /> -->
      <tool-bar title="时间选择" leftTitle="取消" rightTitle="确定" @confirm="confirmTimeSelect" @cancel="cancelTimeSelect" />
      <div class="prpupWrap">
        <div class="left">
          <van-sidebar v-model="activeKey" @change="selectWeekDay">
            <van-sidebar-item :title="index == 0? '今天' + item: index == 1? '明天' + item : index == 2? '后天' + item: item" v-for="(item,index) in dayList" :key="index" />
          </van-sidebar>
        </div>
        <div class="right">
          <van-radio-group v-model="radio" @change="selectedTime">
            <van-cell-group>
              <van-cell :title="item" clickable v-for="(item,index) in timeList" :key="index" @click="radio = index + 1">
                <template #right-icon>
                  <van-radio :name="index + 1" checked-color="#EE0A24" />
                </template>
              </van-cell>
            </van-cell-group>
          </van-radio-group>
        </div>
      </div>
      <!-- <van-button color="linear-gradient(to right, #ff6034, #ee0a24)" block @click='onclick'>
        {{selectTimeDate.buttonTitle}}
      </van-button> -->
    </van-popup>
  </div>
</template>
<script>
import { Dialog } from "vant";
import ToolBar from "@/components/effect/ToolBar";
export default {
  props: {
    iniTime: {
      type: String,
      default: "",
    },
    selectTimeDate: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      Show: false,
      activeKey: 0,
      radio: 1,
      dayList: [],
      timeList: [
        "06:00 - 08:00",
        "08:00 - 10:00",
        "10:00 - 12:00",
        "12:00 - 14:00",
        "14:00 - 16:00",
        "16:00 - 18:00",
        "18:00 - 20:00",
        "20:00 - 22:00",
      ],

      visit_time_week: "",
      visit_time_time: "06:00 - 08:00",
      visit_time: "",
    };
  },
  created() {
    for (let i = 0; i < 7; i++) {
      this.dayList[i] = this.getFutureDate(i);
    }
    this.visit_time_week = this.dayList[this.activeKey];
  },
  mounted() {},
  components: {
    ToolBar,
  },
  methods: {
    timeSelect() {
      this.Show = true;
    },
    toLastIndex() {
      this.$router.go(-1);
    },
    selectedTime(e) {
      this.visit_time_time = this.timeList[e - 1];
    },
    selectWeekDay(e) {
      // console.log(e);
      console.log(this.activeKey);
      this.visit_time_week = this.dayList[this.activeKey];
      // console.log(this.visit_time_week);
    },
    cancelTimeSelect() {
      this.Show = false;
    },
    confirmTimeSelect() {
      let nowYear = new Date().getFullYear();
      console.log(nowYear);
      switch (this.selectTimeDate.event) {
        case "submitTimer":
          this.visit_time =
            nowYear + "-" + this.visit_time_week + "-" + this.visit_time_time;
          this.$emit(
            "getVisitTime",
            `${nowYear}-${this.visit_time_week} ${
              this.visit_time_time.split(" - ")[0]
            }`
          );
          this.Show = false;
          break;
        case "changeTimer":
          let temp_visit_time =
            nowYear + "-" + this.visit_time_week + "-" + this.visit_time_time;
          this.$http
            .post("/api/worker/changeReservation", {
              order_id: this.selectTimeDate.orderId,
              visit_time: `${nowYear}-${this.visit_time_week} ${
                this.visit_time_time.split(" - ")[0]
              }`,
            })
            .then((res) => {
              console.log(res);
              Dialog.confirm({
                message: `您修改的时间段为 \n ${temp_visit_time}`,
              })
                .then(() => {
                  this.visit_time = temp_visit_time;
                  this.$emit("getVisitTime", this.visit_time);
                  this.Show = false;
                })
                .catch(() => {});
            });
          break;
      }
    },
  },
};
</script>
<style lang="less" scoped>
#SelectTime {
  margin: 0.5rem 0;
  .van-cell_title {
    display: flex;
    align-items: center;
    .custom-title {
      margin-right: 1rem;
      font-size: 1.4rem;
    }
  }
  .van-cell__value {
    white-space: nowrap;
  }
  van-popup van-popup--bottom {
    overflow: hidden;
  }
  .van-nav-bar {
    border-bottom: 1px solid #f7f8fa;
    height: 5rem;
  }
  .prpupWrap {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.4rem;
    .left {
      .van-sidebar {
        overflow-y: scroll;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* IE 10+ */
        height: 30rem;
        width: 110px;
        .van-sidebar-item {
          white-space: nowrap;
        }
      }
    }
    .van-sidebar::-webkit-scrollbar {
      width: 0px;
    }
    .right {
      height: 30rem;
      flex: 1;
      overflow: auto;
      overflow-y: scroll;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10+ */
    }
    .right::-webkit-scrollbar {
      width: 0px;
    }
  }
}
</style>