import axios from 'axios'

class QueryDicationary {
  constructor() {
    this.domain = 'https://api.yishifu.com'
  }

  getDicationary(obj) {
    return new Promise((resolve, reject) => {
      axios.get(this.domain + '/api/v2/common/fields/dictionary')
        .then(res => {
          let fields = res.data.fields
          let _arr = []
          for (let key in obj) {
            console.log(obj[key])
            // _arr.push( + obj[key])
            let _temp_obj = fields.find(el => el.field_form_name == key)
            if (_temp_obj) {
              _arr.push(_temp_obj.field_name + '：' + obj[key])
            }
          }
          resolve(_arr)
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  // getCommoditys(obj) {
  //   console.log(obj)
  //   return new Promise((resolve, reject) => {
  //     axios.get(this.domain + '/api/v2/common/fields/dictionary')
  //       .then(res => {
  //         let dictionary = res.data.fields
  //         for (let objKey in obj) {
  //           obj[objKey].a = []
  //           console.log(obj[objKey].additionals)
  //           if (obj[objKey].additionals) {
  //             let fields = obj[objKey].additionals.fields
  //             for (let key in fields) {
  //               if (dictionary.find(el => el.field_form_name == key)) {
  //                 obj[objKey].a.push(dictionary.find(el => el.field_form_name == key).field_name + '：' + fields[key])
  //               }
  //               console.log(obj[objKey])
  //             }
  //           }
  //         };
  //         // console('Obj', obj)
  //         resolve(obj)
  //       })
  //       .catch(err => {
  //         reject(err)
  //       })
  //   })
  // }
}

export default QueryDicationary