<template>
  <div id="toolbar">
    <button type="button" @click="clickLeft" class="left_text">{{leftTitle}}</button>
    <div class="toolbar_title flexCenter">{{title}}</div>
    <button type="button" @click="clickRight">{{rightTitle}}</button>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "标题",
    },
    leftTitle: {
      type: String,
      default: "取消",
    },
    rightTitle: {
      type: String,
      default: "",
    },
  },
  methods: {
    clickLeft() {
      this.$emit("cancel");
    },
    clickRight() {
      this.$emit("confirm");
    },
  },
};
</script>

<style lang="less">
#toolbar {
  display: flex;
  height: 44px;
  align-items: center;
  margin-bottom: 1rem;
  button {
    flex: 1;
  }
  .toolbar_title {
    flex: 3;
    font-size: @theme-font-size-l;
    font-weight: 600;
  }
  button {
    font-size: @theme-font-size-m;
    border: none;
    background: none;
  }
  .left_text {
    color: #969799;
  }
}
</style>